import React, { useCallback, useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import {
  getAdmissionKit,
  getEmployees,
  getEnrollForm,
  getESocialReceipt,
} from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import EmployeeTable from "./components/EmployeeTable";
import EnrollFormDialog from "./components/EnrollFormDialog";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Employees() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [enrollFormPdfBase64, setEnrollFormPdfBase64] = useState("");
  const [enrollFormLoading, setEnrollFormLoading] = useState(false);

  const companyCode = searchParams.get("company_code");

  const tablePageIndex = Number(searchParams.get("page") ?? 1);
  const tablePageSize = Number(searchParams.get("page_size") ?? 50);

  const employeeQuery = useQuery(
    ["employees", companyCode, tablePageIndex, tablePageSize, searchQuery],
    () =>
      getEmployees(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        searchQuery,
        {
          mostrarFuncao: true,
          mostrarCentroCusto: true,
          page: tablePageIndex,
          perPage: tablePageSize,
        },
      ),
    {
      enabled: !!companyCode,
    },
  );

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const searchDelayed = debounce(query => {
    setSearchQuery(query);
  }, 1000);

  const employees = employeeQuery?.data?.data?.data ?? [];
  const rows = employeeQuery?.data?.data?.meta?.total ?? 0;

  const handleGenerateEnrollForm = useCallback(
    async enrollNumber => {
      setEnrollFormLoading(true);

      getEnrollForm(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        enrollNumber,
      )
        .then(content => {
          setEnrollFormPdfBase64(`data:application/pdf;base64,${content}`);
        })
        .catch(() => {
          toast.error(
            "Erro ao tentar gerar a ficha de registro do funcionário.",
          );
        })
        .finally(() => {
          setEnrollFormLoading(false);
        });
    },
    [companyCode],
  );

  const handleGenerateAdmissionKit = useCallback(
    async enrollNumber => {
      setEnrollFormLoading(true);

      getAdmissionKit(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        enrollNumber,
      )
        .then(content => {
          setEnrollFormPdfBase64(`data:application/pdf;base64,${content}`);
        })
        .catch(() => {
          toast.error(
            "Erro ao tentar gerar kit de admissão ao funcionário.",
          );
        })
        .finally(() => {
          setEnrollFormLoading(false);
        });
    },
    [companyCode],
  );

  const handleGenerateESocialReceipt = useCallback(
    async (enrollNumber, minDate) => {
      setEnrollFormLoading(true);

      getESocialReceipt(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        enrollNumber,
        minDate,
      )
        .then(content => {
          setEnrollFormPdfBase64(`data:application/pdf;base64,${content}`);
        })
        .catch(() => {
          toast.error(
            "Erro ao tentar gerar a recibo eSocial do funcionário.",
          );
        })
        .finally(() => {
          setEnrollFormLoading(false);
        });
    },
    [companyCode],
  );

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Funcionários
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={(employeeQuery?.isLoading ?? true) || enrollFormLoading}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              searchDelayed(event.target.value);
            }}
          />
        </Box>
        <EnrollFormDialog
          uri={enrollFormPdfBase64}
          onClose={() => setEnrollFormPdfBase64("")}
        />
        <EmployeeTable
          data={employees}
          rows={rows}
          onShowEnrollForm={handleGenerateEnrollForm}
          onShowAdmissionKitForm={handleGenerateAdmissionKit}
          onShowESocialReceiptForm={handleGenerateESocialReceipt}
        />
      </Box>
    </Dialog>
  );
}
